import React     from "react";
import PropTypes from "prop-types";

function withCountdown(WrappedComponent) {
	class withCountdown extends React.Component {

		static getStateFromTime(time) {
			return {
				days:         Math.floor(time / 86400000),
				hours:        Math.floor(time / 3600000) % 24,
				minutes:      Math.floor(time / 1000 / 60) % 60,
				seconds:      Math.floor(time / 1000) % 60,
				milliseconds: time % 1000,
			};
		}

		constructor(props) {
			super(props);

			this.interval = null;

			this.state = withCountdown.getStateFromTime(this.getRemainingTime());

			this.startCountdown   = this.startCountdown.bind(this);
			this.stopCountdown    = this.stopCountdown.bind(this);
			this.getRemainingTime = this.getRemainingTime.bind(this);
			this.setRemainingTime = this.setRemainingTime.bind(this);

		}

		componentDidMount() {
			this.startCountdown();
		}

		componentWillUnmount() {
			this.stopCountdown();
		}

		startCountdown() {
			if (this.getRemainingTime() !== 0) {
				this.interval = setInterval(this.setRemainingTime, this.props.updateInterval);
			}
		}

		stopCountdown() {
			if (this.interval && typeof this.props.onCountdownEnd === 'function') {
					this.props.onCountdownEnd();
				}

			clearInterval(this.interval);
			this.interval = null;
		}

		getRemainingTime() {
			const timeDiff = this.props.end.getTime() - Date.now();

			return timeDiff < 0 ? 0 : timeDiff;
		}

		setRemainingTime() {
			const remainingTime = this.getRemainingTime();

			if (remainingTime === 0) {
				this.stopCountdown();
			}

			this.setState(withCountdown.getStateFromTime(remainingTime));
		}

		render() {
			return <WrappedComponent countdown={this.state} {...this.props} />;
		}
	}

	withCountdown.defaultProps = {
		updateInterval: 1000,
	};

	withCountdown.propTypes = {
		end:            PropTypes.instanceOf(Date).isRequired,
		updateInterval: PropTypes.number,
		onCountdownEnd: PropTypes.func,
	};

	return withCountdown;
}


export default withCountdown;